p, a, h1, h2, h3, h4, h5, h6, div, span, strong, table {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--blue);
  letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: 1.875rem;
  margin-bottom: 2rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

.text-grey {
  color: var(--darker-grey);
}

.text-yellow {
  color: var(--yellow);
}

.text-dark-yellow {
  color: var(--dark-yellow);
}

.text-white {
  color: var(--white);
}

.text-blue {
  color: var(--blue);
}

.text-red {
  color: var(--red);
}

.text-sm {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1.25rem;
}

.text-xl {
  font-size: 2.25rem;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}