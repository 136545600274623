.sidebar-container {
  z-index: 1;
}

.sidebar {
  position: fixed;
  min-width: 270px;
  transition: all .2s ease-in-out;
}

.content {
  margin-left: 270px;
}

.sidebar .logo {
  margin-bottom: 10px;
}

.sidebar .logo > svg {
  max-width: 150px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.sidebar .top-menu, .sidebar .bottom-menu {
  padding-right: 10px;
  padding-left: 10px;
}

.sidebar .top-menu {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

sidebar .bottom-menu {
  justify-content: space-between;
}

.sidebar .top-menu > a, .sidebar .bottom-menu > a {
  padding: 10px;
  margin: 3px 0;
  transition: all .2s ease-in;
}

.sidebar .top-menu > a.selected, .sidebar .bottom-menu > a.selected,
.sidebar .top-menu > a:hover, .sidebar .bottom-menu > a:hover {
  background-color: var(--light-yellow  );
  color: var(--blue);
  font-weight: 500;
}

.sidebar .top-menu > a .lni, .sidebar .bottom-menu > a .lni {
  margin-right: 10px;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 1.2rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem 0.3rem 1rem;
  transition: all .2s ease-in;
}

.sidebar-container.show-mobile .mobile-menu {
  background: var(--white);
  border-radius: 8px;
  border-style: solid;
  box-shadow: var(--box-shadow);
}

@media only screen and (max-width : 768px) {
  .content {
    margin-left: 0;
  }
  .mobile-menu {
    display: block;
  }

  .sidebar {
    margin-left: -100vw;
  }

  .sidebar-container.show-mobile .sidebar {
    margin-left: 0;
  }
}