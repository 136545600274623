
@tailwind base;
@tailwind components;
@tailwind utilities;
.property .breadcrumb .divider {
  @apply px-2; /* Applied padding */
}

.property .images {
  @apply flex flex-row flex-nowrap gap-10; /* Applied display, direction, and gap */
}


.property .images img {
  @apply w-full h-auto; /* Applied width and height */
}

.property .images .big-image {
  @apply flex-1; /* Applied flex */
}

.property .images .small-images {
  @apply grid grid-cols-2 gap-10 flex-1 max-h-96 overflow-auto; /* Applied grid layout, gap, flex, max height, and overflow */
}

.property .documents > div {
  @apply cursor-pointer transition duration-300; /* Applied cursor and transition */
}

.property .documents > div:hover {
  @apply bg-light-grey; /* Applied background color on hover */
}

.property .add-to-cart {
  @apply sticky top-20 self-start; /* Applied sticky positioning and alignment */
}


.custom-quill .ql-container {
  min-height: 150px; 
  font-family: inherit; 
}

.custom-quill .ql-editor {
  padding: 10px; 
  
}

.custom-quill .ql-editor::before {
  color: #aaa; 
  font-style: italic;
  white-space: normal; 
}

.custom-quill .ql-editor {
  line-height: 1.6; /* Adjust line height to match the website */
  margin-bottom: 1.5rem; /* Adjust paragraph spacing */
  font-family: 'Plus Jakarta Sans', sans-serif; /* Ensure consistent font */
}

.custom-quill .ql-editor p {
  margin-bottom: 1.5rem; /* Adjust paragraph spacing */
}

.custom-quill .ql-editor h1, 
.custom-quill .ql-editor h2 {
  margin-top: 2rem; /* Add space above headers */
  margin-bottom: 1.5rem; /* Add space below headers */
}



@media (max-width: 768px) {
  .property .images {
    @apply flex-col; 
  }
  
  .property .images .big-image,
  .property .images .small-images {
    @apply flex-1; 
  }

  .property .images .small-images {
    @apply grid-cols-1; 
  }
}
