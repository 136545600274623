@tailwind base;
@tailwind components;
@tailwind utilities;


@import 'app.css';
@import 'typography.css';
@import 'layout.css';

@import 'sidebar.css';

@import 'rewards.css';
@import 'account.css';
@import 'property.css';
@import 'cms-sidebar.css';