/* Sidebar Container */
.cms-sidebar-container {
    display: flex;
  }
  
  /* Sidebar Styles */
  .cms-sidebar {
    min-width: 270px;
    transition: all .2s ease-in-out;
    margin-left: 270px; /* Adjust this value if you want a gap between the two sidebars */
    overflow: auto;
  }
  
 
  
  .cms-sidebar .logo {
    margin-bottom: 10px;
  }
  
  .cms-sidebar .logo > svg {
    max-width: 150px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .cms-sidebar .top-menu,
  .cms-sidebar .bottom-menu {
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .cms-sidebar .top-menu {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .cms-sidebar .bottom-menu {
    justify-content: space-between;
  }
  
  .cms-sidebar .top-menu > a,
  .cms-sidebar .bottom-menu > a {
    padding: 10px;
    margin: 3px 0;
    transition: all .2s ease-in;
  }
  
  .cms-sidebar .top-menu > a.selected,
  .cms-sidebar .bottom-menu > a.selected,
  .cms-sidebar .top-menu > a:hover,
  .cms-sidebar .bottom-menu > a:hover {
    background-color: var(--light-yellow);
    color: var(--blue);
    font-weight: 500;
  }
  
  .cms-sidebar .top-menu > a .lni,
  .cms-sidebar .bottom-menu > a .lni {
    margin-right: 10px;
  }
  
  .cms-mobile-menu {
    display: none;
    position: absolute;
    top: 1.2rem;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;
    padding: 0.5rem 1rem 0.3rem 1rem;
    transition: all .2s ease-in;
  }
  
  .cms-sidebar-container.show-mobile .cms-mobile-menu {
    background: var(--white);
    border-radius: 8px;
    border-style: solid;
    box-shadow: var(--box-shadow);
  }
  
  @media only screen and (max-width: 768px) {
    .content {
      margin-left: 0;
    }
  
    .cms-mobile-menu {
      display: block;
    }
  
    /* .cms-sidebar {
      margin-left: -100vw;
    } */
  
    .cms-sidebar {
      
        margin-left: 0px; 
        overflow: auto;
      }
    .cms-sidebar-container.show-mobile .cms-sidebar {
      margin-left: 0;
    }
  }
  