.account {
  max-width: 640px;
  margin: 0 auto;
  align-items: center;
}

.account .logo {
  max-width: 200px;
  text-align: center  ;
}

.account h1 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 100px;
}

.account h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0px;
}

.account p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  max-width: 770px;
  text-wrap: pretty;
  margin: 0 auto;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 40px;
}

.account form {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 25px #0000001a;
  margin: 0 auto;
  max-width: 700px;
  padding: 35px;
  width: 80%;
}

.account form .info {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
}

.account form .error {
  margin-bottom: 0;
  padding: 1rem;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #ff0000;
  margin-left: 1rem;
  margin-right: 1rem;
}

.account form .form-group {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.account form .form-group label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0px;
  margin-top: -10px;
}

.account form .form-group input {
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #D3D9E5;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .account form {
    width: 100%;
    margin: 0 auto;
  }
}