*,
*::before,
::after {
  --blue: #14223d;
  --light-yellow: #f5ca48aa;
  --yellow: #f5ca48;
  --dark-yellow: #e2b019;
  --white: #ffffff;
  --lighter-grey: #e2e8f0;
  --light-grey: #f6f7f9;
  --lightest-grey: #d3d9e5;
  --dark-grey: #a3abba;
  --darker-grey: #5e6473;
  --red: #ff0000;
  --background: #f6f7f9;
  --box-shadow: 0px 0.25px 1px 0px #0000000a, 0px 0.6px 2.5px 0px #00000007,
    0px 1.15px 4.75px 0px #00000006, 0px 2px 8.5px 0px #00000005,
    0px 3.75px 15.85px 0px #00000004, 0px 9px 38px 0px #00000003;
}

.border-color {
  @apply border-neutral-200;
}

*,
*::before,
::after {
  border-color: var(--lighter-grey);
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--background);
}
.info {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
}

hr {
  border: 0;
  height: 2px;
  background-color: var(--lighter-grey);
  margin: 1rem 0;
}

#root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.content {
  padding: 2rem;
  width: 100%;
  max-width: 1280px;
}

@media (max-width: 600px) {
  .content {
    max-width: 100%;
  }
}

.content.full {
  max-width: 100%;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--white);
  border-style: solid;
  border-width: 1.5px;
  border-radius: 8px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease-in;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 32px;
  height: 0.75rem;
  min-width: 4.5rem;
  font-size: 1rem;
  background: var(--blue) !important;
  background-color: var(--blue) !important;
  color: var(--white) !important;
  border-style: solid;
  cursor: pointer;
}

.btn .lni {
  margin-right: 10px;
  font-size: 1.25rem;
}

.btn.btn-secondary {
  background: var(--light-grey) !important;
  color: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
.btn.btn-red {
  background: var(--red) !important;
  color: var(--white) !important;
}
.btn- .btn:hover {
  background: var(--dark-grey);
}

.btn.btn-full {
  width: calc(100% - 64px);
}

.tabs {
  margin-bottom: 2rem;

  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background-color: var(--white);
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-radius: 9999px;
  box-shadow: var(--box-shadow);
  max-width: 475px;
}

.tabs .tab-item {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition: all 0.2s ease-in;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 16px 32px 16px 32px;
  height: 0.5rem;
  min-width: 2rem;
  font-size: 0.875rem;
  border-color: var(--dark-grey);
  background-color: var(--white);
  color: var(--blue);
  border-radius: 9999px;
  border-width: 0px;
  flex: 1 1 0%;
  cursor: pointer;
}

.tabs .tab-item:hover {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.tabs .tab-item.selected {
  background-color: var(--light-yellow);
}

.tag {
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background-color: var(--light-grey);
  padding: 2px 5px;
  font-size: 0.75rem;
  width: fit-content;
}

.progress .baseline {
  width: 100%;
  height: 8px;
  background-color: var(--lightest-grey);
  border-radius: 9999px;
}

.progress .current {
  height: 8px;
  background-color: var(--dark-yellow);
  border-radius: 9999px;
  position: relative;
  top: -8px;
}
.error {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #ff0000;
}

.yellow-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #ffd000; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f6f7f9; /* Adds some padding around the thumb */
}

.yellow-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #e2af17; /* Color of the thumb when hovered */
}

.yellow-scrollbar ::-webkit-scrollbar-track {
  background-color: #f3f4f6; /* Color of the scrollbar track */
  border-radius: 6px; /* Roundness of the scrollbar track */
}

.yellow-scrollbar {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #ffd000 #f3f4f6; /* thumb color and track color */
}
